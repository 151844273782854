<template>
  <div class="page">
    <h1 class="primary--text mb-10" style="font-size: 24px">Privacy policy</h1>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Parturient
      suspendisse mauris, turpis nulla egestas lectus sit. Non nullam morbi
      parturient dignissim sociis enim. Laoreet lobortis condimentum tortor
      semper tellus est, donec adipiscing. Senectus sem nisi, dolor lacus
      condimentum scelerisque scelerisque mi. Nullam enim egestas enim morbi
      senectus. Dignissim in condimentum vel lectus. Integer risus ultricies
      nullam hac velit pellentesque maecenas. Sollicitudin tortor sit malesuada
      nisi, est mi non leo. Odio mauris massa cursus adipiscing venenatis
      habitasse. Velit integer sit tempor et congue lorem maecenas. Mattis eget
      elit, vivamus amet quis tristique enim, dui. Dui tortor, ut feugiat
      parturient lorem ac, proin semper. Ultricies neque augue diam non augue
      pharetra suspendisse habitant pellentesque. Est gravida donec eu pretium
      praesent nunc aliquam. Quisque nullam volutpat scelerisque dolor fringilla
      sit. Aliquam curabitur morbi ultrices sit luctus. Tortor ultricies nunc
      senectus turpis diam lorem turpis diam rutrum. Ut purus integer elementum
      duis quisque volutpat. Lacus nec diam, pulvinar ornare in. Etiam ultricies
      semper tempus feugiat vitae.
    </p>

    <p>
      Nullam non lacinia consectetur quis. Eu, pharetra aliquam dictum magna
      commodo, massa. Urna, cursus feugiat mattis praesent. Ullamcorper mi
      justo, eget cursus mauris, et cras. Semper eu senectus feugiat sagittis
      egestas cras scelerisque. Aliquam enim laoreet morbi vitae purus, faucibus
      cras in. Enim neque, sit pulvinar proin euismod turpis mus. Orci, sit
      gravida mattis donec semper semper ac enim. Interdum diam, est tempor
      fringilla quis odio pellentesque quam. Amet donec enim eu vitae. Egestas
      mi turpis massa viverra sed aliquam. Purus in suspendisse massa habitasse.
      Aliquet aliquam mauris sit tortor eget magna vel arcu. Sapien, a vulputate
      lacus, pretium tristique. Id fermentum mattis in duis platea. Amet donec
      feugiat nulla non vulputate faucibus porta vestibulum. Sed ipsum,
      facilisis consectetur purus. Diam suspendisse praesent faucibus magna
      morbi amet egestas porta semper. Diam cras vitae velit lectus nisl.
      Habitant duis suspendisse facilisi montes, etiam ac. Justo faucibus leo
      sit faucibus proin iaculis elementum non. In pulvinar elit sed blandit
      morbi. Non lacus, non mauris viverra blandit nibh sem nibh. Adipiscing
      quam neque felis accumsan, sed diam sed cras. Lacus sit ut sit neque
      blandit lorem ultrices. Orci, laoreet lorem senectus integer lectus. Urna
      tellus adipiscing augue urna euismod nisi, sagittis integer. Venenatis,
      orci euismod at aliquet purus est, cum et est. Turpis diam, tellus integer
      cras. Aliquet eu ac elit suspendisse. Proin purus nec, feugiat donec
      lobortis nulla sit aliquet quis. Aliquam mi mauris malesuada amet, a, arcu
      risus. Sed dictum facilisi eu posuere sed. Gravida amet sit euismod fames
      faucibus egestas. Enim id euismod ipsum, ipsum sollicitudin felis et
      eleifend.
    </p>

    <p>
      Non volutpat interdum amet, risus dis dignissim. Id euismod consectetur
      amet, diam pellentesque. Malesuada vulputate amet sed iaculis ultrices
      pharetra ultricies ultrices. Nibh auctor id odio mattis viverra placerat.
      Donec lorem malesuada adipiscing nisi, hendrerit ornare netus augue
      scelerisque. Egestas duis euismod feugiat donec lacus. Dui in diam semper
      egestas bibendum.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: { title: "Privacy policy" },
};
</script>
